'use client';

import React from 'react';
import { useSession } from 'next-auth/react';
import { Button } from "@/components/ui/button";
import LogoutButton from './LogoutButton'; // Ensure this is a client component
import { useRouter } from 'next/navigation';

const AuthButtons = () => {
  const { data: session, status } = useSession();
  const router = useRouter(); // Initialize the router

  if (status === 'loading') {
    return (
      <Button variant="ghost" disabled>
        Loading...
      </Button>
    );
  }

  if (session?.user?.id) {
    return <LogoutButton />;
  } else {
    return (
<div className="flex gap-2">
  <Button
    onClick={() => router.push('/auth')}
    className="min-w-[100px] bg-blue-500 text-white text-md rounded-full text-xl"
    aria-label="Sign up"
  >
    Sign up
  </Button>

  <Button
    onClick={() => router.push('/auth')}
    className="min-w-[100px] bg-blue-500 text-white text-md rounded-full text-xl"
    aria-label="Login"
  >
    Login
  </Button>
</div>

    );
  }
};

export default AuthButtons;
